<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">
    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
              class="btn-primary"
              color="primary"
              @click="setShowApiTokenAddOrEditDialog(true)">
            {{ $t('common.api-tokens.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12" class="mt-3">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            sort-by="createdAt"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Label -->
          <template v-slot:item.label="{item}">
            <span>{{ item.label || truncate }}</span>
          </template>

          <!-- Token -->
          <template v-slot:item.token="{item}">
            <span v-if="revealTokens.includes(item.id)">
              {{ item.token }}
              <ButtonIconCopy class="ml-1" :value="item.token" />
            </span>
            <span v-else>******</span>
          </template>

          <!-- Created at -->
          <template v-slot:item.createdAt="{item}">
            <span>{{ formatDatetime(item.createdAt) }}</span>
          </template>

          <!-- Actions -->
          <template v-slot:item.action="{item}">

            <!-- Edit --
            <v-btn icon @click="setShowApiTokenEditDialog(true, item)">
              <v-icon small>fas fa-pencil</v-icon>
            </v-btn-->

            <!-- Reveal btn -->
            <v-btn icon @click="toggleRevealToken(item.id)">
              <v-icon small v-if="revealTokens.includes(item.id)">fas fa-eye-slash</v-icon>
              <v-icon small v-else>fas fa-eye</v-icon>
            </v-btn>

            <!-- Delete -->
            <v-btn icon @click="setShowApiTokenDeleteDialog(true, item)">
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- Add or Edit dialog -->
    <ApiTokenMainDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :item="currentApiToken"
      :account-id="account.id"
      @close="(needRefresh) => setShowApiTokenAddOrEditDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('common.api-tokens.deleteDialog.title')"
      @submit="deleteApiToken"
      @close="(needRefresh) => setShowApiTokenDeleteDialog(false, null, needRefresh)"
    />
  </v-card>
</template>

<script>
import vuetifyDatatable            from '@/mixins/vuetifyDataTable';
import formatter                   from "@/mixins/formatter";

export default {

  name: "AccountApiTokensTab",
  components: {
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
    ApiTokenMainDialog: () => import("@/components/Common/ApiTokens/ApiTokenMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog")
  },

  props: {
    account: {type: Object, default: () => null}
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      isLoaded: false,
      currentApiToken: null,
      revealTokens: [],

      showDialog: {
        addOrEdit: false,
        delete: false
      },

      headers: [
        {
          text: this.$t('common.api-tokens.datatable.headers.label'),
          value: "label",
          sortable: false,
          width: "20%"
        },
        {
          text: this.$t('common.api-tokens.datatable.headers.token'),
          value: "token",
          sortable: false,
          width: "30%"
        },
        {
          text: this.$t('common.api-tokens.datatable.headers.createdAt'),
          value: "createdAt",
          sortable: false,
          width: "15%"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "10%"
        }
      ],

      URL_API: {
        GET_ALL: `/accounts/${this.account.id}/api-tokens`
      },
    }
  },

  methods: {

    deleteApiToken() {
      this.$http
          .delete(`/accounts/${this.account.id}/api-tokens/${this.currentApiToken.id}`, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.api-tokens.deleteDialog.success'));
            this.setShowApiTokenDeleteDialog(false, null, true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtnSave = false;
          });
    },

    setShowApiTokenAddOrEditDialog(val, apiToken = null, reloadData = false) {
      this.currentApiToken = apiToken;
      if (reloadData)
        this.searchTrigger();

      this.showDialog.addOrEdit = val;
    },

    setShowApiTokenDeleteDialog(val, apiToken = null, reloadData = false) {
      this.currentApiToken = apiToken;
      if (reloadData)
        this.searchTrigger();

      this.showDialog.delete = val;
    },

    toggleRevealToken(apiTokenId = null) {
      const index = this.revealTokens.indexOf(apiTokenId);
      if (index > -1)
        this.revealTokens.splice(index, 1);
      else
        this.revealTokens.push(apiTokenId);
    }
  }
}
</script>

<style scoped>

</style>
